import React from 'react';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { AttributesTableItem, AttributesValueOptions } from '@app/models/chat.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import compositeElements from '@assets/data/elements.composite.json';
import simpleElements from '@assets/data/elements.simple.json';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body, ButtonText, H1 } from '@atomic/atm.typography';
import { Tab } from '@atomic/mol.tab';
import { Table, TD, TH, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { Visibility } from '@atomic/obj.visibility';
import { TechnicalAnalysisStrings } from '../quote/technical-analysis/technical-analysis.string';
import { rangeLimits } from './chat.contants';
import { ChatStrings } from './chat.string';
import {
  CompositionTabsWrapperStyled,
  CompositionContentWrapperStyled,
  CompositionSearchWrapperStyled,
  CompositionTabForm,
  CompositionTabList,
  CompositionWrapper,
} from './modal-composition.component.style';

export interface CompositionContext {
  ELEMENTOS: AttributesTableItem[];
}

interface ModalCompositionProps {
  openTableType: string;
  data: CompositionContext;
  onSubmit: (data: TableRowData[], key: string) => void;
  onClose: () => void;
}

interface CompositionProps {
  element: string;
  min: string;
  max: string;
  unit: string;
  index: number;
  strings?: any;
  onRemove: (element: string) => void;
  activeElements?: string[];
  setSelectedElements?: any;
  selectedElements?: string[];
}

const convertData = data => {
  return data.ELEMENTOS?.reduce((acc, cur) => {
    const currentElement = cur.elements ? cur.elements : cur.elemento;
    acc[currentElement] = { min: cur.min, max: cur.max, unit: cur.unit };
    return acc;
  }, {});
};

const sortedElements = data => {
  return data.sort((a: AttributesValueOptions, b: AttributesValueOptions) =>
    a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
  );
};

const MAX_VALUE_PORC = 9;
const MAX_VALUE_PPM = 9999;

export const formatDecimals = (value: string) => {
  return value.toString().replace(',', '.');
};

const allElements = simpleElements.concat(compositeElements);

const elementsOrder = ['C', 'Mn', 'Si', 'P', 'S', 'Cr', 'Ni', 'Mo', 'Cu'];
export const ElementSort = (data, field) => {
  const sorting = elementsOrder.reduce(
    (object, item, index) => ({
      ...object,
      [item]: index,
    }),
    {},
  );
  return data.sort((a, b) => sorting[a[field]] - sorting[b[field]]);
};

const mandatoryElementsList = ['C', 'Mn', 'Si'];

const Composition: React.FC<CompositionProps> = props => {
  const [unitEl, setUniEl] = React.useState(props.unit);
  const [min, setMin] = React.useState(props.min);
  const [max, setMax] = React.useState(props.max);

  const handleChangeUnit = e => {
    setUniEl(e);
  };

  const handleRemove = () => {
    props.onRemove(props.element);
  };

  const handleChangeMin = value => {
    setMin(value);
  };

  const handleChangeMax = value => {
    setMax(value);
  };

  const handleChangeCQ = value => {
    props.onRemove(props.element);
    props.setSelectedElements(prev => {
      return [...prev, value];
    });
  };

  return (
    <TR>
      <TD>
        <Form.Field
          name={`ELEMENTOS[${props.index}].elemento`}
          initialValue={props.element}
          hideErrorCaption
          onValueChange={value => handleChangeCQ(value)}
          validators={[Validators.Required(props.strings.fields.alert)]}
        >
          {mandatoryElementsList.includes(props?.element) ? (
            <Body>{props.element}</Body>
          ) : (
            <SelectField>
              <option value=''>{props.element}</option>
              {props.activeElements !== undefined &&
                sortedElements(props.activeElements).map(el => (
                  <option value={el.key} key={el.label}>
                    {el.label}
                  </option>
                ))}
            </SelectField>
          )}
        </Form.Field>
      </TD>
      <TD>
        <Form.Field
          initialValue={props.min}
          name={`ELEMENTOS[${props.index}].min`}
          onValueChange={value => handleChangeMin(value)}
          hideErrorCaption
          validators={[
            Validators.MaxValue(unitEl === 'ppm' ? MAX_VALUE_PPM : MAX_VALUE_PORC, min, props.strings.fields.alertMax),
            Validators.HasAtLeastOne([min, max], props.strings.fields.alert),
            Validators.MinMoreThanMax([min, max], props.strings.fields.alertMinMoreMax),
          ]}
        >
          <CompositionWrapper>
            <TextField placeholder='Min.' type='number' />
          </CompositionWrapper>
        </Form.Field>
      </TD>
      <TD> - </TD>
      <TD>
        <Form.Field
          initialValue={props.max && props.max.toString().replace(rangeLimits.maxString, '')}
          name={`ELEMENTOS[${props.index}].max`}
          onValueChange={value => handleChangeMax(value)}
          hideErrorCaption
          validators={[
            Validators.MaxValue(unitEl === 'ppm' ? MAX_VALUE_PPM : MAX_VALUE_PORC, max, props.strings.fields.alertMax),
            Validators.HasAtLeastOne([min, max], props.strings.fields.alert),
            Validators.MinMoreThanMax([min, max], props.strings.fields.alertMinMoreMax),
          ]}
        >
          <CompositionWrapper>
            <TextField placeholder='Máx.' type='number' />
          </CompositionWrapper>
        </Form.Field>
      </TD>
      <TD>
        <Form.Field
          initialValue={props.unit?.toLowerCase() || '%'}
          onValueChange={e => handleChangeUnit(e)}
          name={`ELEMENTOS[${props.index}].unit`}
        >
          <SelectField>
            <option value='%'>%</option>
            <option value='ppm'>PPM</option>
          </SelectField>
        </Form.Field>
      </TD>
      <TD>
        {!mandatoryElementsList.includes(props?.element) && (
          <Button kind='link' onClick={handleRemove}>
            <FaIcon.Trash />
          </Button>
        )}
      </TD>
    </TR>
  );
};

const CompositionTab = props => {
  return (
    <Tab initialIndex={props.tabIndex} onIndexChanged={props.handleTabChange}>
      {props.labels.map((item, index) => (
        <Tab.Item key={index}>
          <Hbox>
            <Hbox.Item>{item}</Hbox.Item>
          </Hbox>
        </Tab.Item>
      ))}
    </Tab>
  );
};

const CompositionList = props => {
  const [elementsList, setElementsList] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchStatus, setSearchStatus] = React.useState(false);

  const convertedElements = (data: any) => {
    if (data.length > 0) {
      return data.reduce((acc, item) => {
        acc.push(item);
        return acc;
      }, []);
    }
  };

  const getElements = () => {
    setElementsList(props.listElements);
  };

  React.useEffect(() => {
    getElements();
  });

  React.useEffect(() => {
    const results = elementsList.filter(el => el.key === searchTerm || el.label.includes(searchTerm));

    if (results.length > 0) {
      setSearchStatus(false);
    } else {
      setSearchStatus(true);
    }

    setSearchResults(results);
  }, [elementsList, searchTerm, props.selectedElements]);

  const handleSearchElement = e => {
    setSearchTerm(e.target.value);
  };

  const normEl = props.normElements ? props.normElements.map(el => el) : [];

  return (
    <Visibility key={props.id} visible={props.tabIndex === props.id}>
      <CompositionSearchWrapperStyled>
        <TextField
          placeholder={`${props.strings.searchPlaceholder} ${props.strings.typeElement[props.id]}`}
          initialValue={props.searchTerm}
          onKeyUp={handleSearchElement}
          hasButton
        >
          <Button kind='link'>
            <FaIcon.Search size='1x' />
          </Button>
        </TextField>
      </CompositionSearchWrapperStyled>

      <VSeparator />
      <Grid fluid>
        <Row>
          {searchStatus ? (
            <Body>{props.strings.searchNoResults}</Body>
          ) : (
            searchResults.map((element, index) => (
              <Col key={index} xs={props.numCols}>
                <CheckboxField
                  key={element.key}
                  id={element.key}
                  initialChecked={
                    props.selectedElements.includes(element.key) ||
                    convertedElements(props.elements).includes(element.key) ||
                    normEl.includes(element.key)
                  }
                  disabled={convertedElements(props.elements).includes(element.key)}
                  onValueChange={(_, checked) => props.handleSelectedElements(element.label, checked)}
                >
                  {element.label}
                </CheckboxField>
              </Col>
            ))
          )}
        </Row>
      </Grid>
    </Visibility>
  );
};

export const ModalComposition: React.FC<ModalCompositionProps> = props => {
  const [mandatoryElements, setMandatoryElements] = React.useState(mandatoryElementsList);
  const [normElements, setNormElements] = React.useState(undefined);
  const [compositionObj, setCompositionObj] = React.useState({});
  const [opened, setOpened] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [selectedElements, setSelectedElements] = React.useState([]);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].modalComposition;

  React.useEffect(() => {
    if (props.data?.ELEMENTOS) {
      const comp = convertData(props.data);
      setCompositionObj(comp);
      setNormElements(Object.keys(comp));
    } else {
      setCompositionObj({});
      setMandatoryElements(mandatoryElementsList);
    }
  }, [props.data]);

  const joinElements = (norm: string[], mandatory: string[]) => {
    const normEl = norm.map(el => el.toLocaleUpperCase());
    const mandEl = mandatory.map(el => el.toLocaleUpperCase());
    const elementsDiff = [];

    mandEl.forEach(e => {
      if (!normEl.includes(e)) {
        elementsDiff.push(e);
      }
    });
    return elementsDiff;
  };

  React.useEffect(() => {
    if (props.openTableType.toLowerCase() === 'elementos') {
      setOpened(true);
    }
  }, [props.openTableType]);

  const validateRange = (values: CompositionContext) => {
    const convertedValues = [];
    values.ELEMENTOS.forEach((element, index) => {
      convertedValues[index] = { min: parseFloat(element.min), max: parseFloat(element.max) };
    });

    return convertedValues.some(el =>
      el.min && el.max
        ? el.min > el.max || ((el.min === 0 || isNaN(el.min)) && (el.max === 0 || isNaN(el.max)))
          ? true
          : false
        : (!el.min && (el.max === 0 || isNaN(el.max))) || ((el.min === 0 || isNaN(el.min)) && !el.max)
        ? true
        : false,
    );
  };

  const handleClose = () => {
    setOpened(false);
    props.onClose();
  };

  const handleSubmit = (formData: FormData<CompositionContext>) => {
    if (Object.keys(formData.error).length) {
      if (
        Object.values(formData.error.ELEMENTOS)[0].max?.name === 'LimitValue' ||
        Object.values(formData.error.ELEMENTOS)[0].min?.name === 'LimitValue'
      ) {
        show('alert', strings.fields.alertMax);
      } else if (
        Object.values(formData.error.ELEMENTOS)[0].max?.name === 'MinMoreThanMax' ||
        Object.values(formData.error.ELEMENTOS)[0].min?.name === 'MinMoreThanMax'
      ) {
        show('alert', strings.fields.alertMinMoreMax);
      }
      return;
    }
    if (validateRange(formData.data)) {
      show('alert', TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell.errorMessages.invalidRange);
    } else {
      props.onSubmit(setTableParams(formData.data), 'CHEMICAL_COMPOSITION');
      setSelectedElements([]);
      setOpened(false);
    }
  };

  const setTableParams = (data: CompositionContext) => {
    return data.ELEMENTOS.reduce((acc, item) => {
      acc.push([
        { columnRef: 'element', value: item.elemento },
        { columnRef: 'min', value: !item.min ? rangeLimits.min : formatDecimals(item.min) },
        { columnRef: 'max', value: !item.max ? rangeLimits.max : formatDecimals(item.max) },
        { columnRef: 'unit', value: item.unit },
      ]);
      return acc;
    }, []);
  };

  const handleRemove = (element: string) => {
    setMandatoryElements(mandatoryElements.filter(_el => element !== _el));
    setSelectedElements(selectedElements.filter(_el => element !== _el));
    if (normElements !== undefined) {
      setNormElements(normElements.filter(_el => element !== _el));
    }
  };

  const handleSelectedElements = (e: string, checked: boolean) => {
    if (checked) {
      setSelectedElements(prev => {
        return [...prev, e];
      });
    } else {
      setSelectedElements(selectedElements.filter(_el => e !== _el));
    }
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const currentListElements = mandatoryElementsList.concat(selectedElements);
  const activeElements = [];
  allElements.forEach(item => {
    if (!currentListElements.includes(item.label)) {
      activeElements.push(item);
    }
  });

  let FAKE_INDEX = 0;

  return (
    <Modal preventOverlayClick opened={opened} onClose={handleClose} small>
      <Scroll.Element>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{strings.title}</H1>
              {step === 2 && (
                <>
                  <Body>{strings.text}</Body>
                  <VSeparator />
                </>
              )}

              <>
                <CompositionTabForm step={step}>
                  <Form onSubmit={handleSubmit}>
                    <Table>
                      <TR>
                        <TH>
                          <Body>{strings.fields.element}</Body>
                        </TH>
                        <TH>
                          <Body>{strings.fields.min}</Body>
                        </TH>
                        <TH></TH>
                        <TH>
                          <Body>{strings.fields.max}</Body>
                        </TH>
                        <TH>
                          <Body>{strings.fields.unit}</Body>
                        </TH>
                      </TR>
                      {normElements !== undefined &&
                        sortedElements(normElements).map((element: string, index: number) => (
                          <Composition
                            index={FAKE_INDEX++}
                            element={element}
                            min={compositionObj[element]?.min && compositionObj[element].min}
                            max={compositionObj[element]?.max && compositionObj[element].max}
                            unit={compositionObj[element]?.unit}
                            key={element + index + 'n'}
                            onRemove={handleRemove}
                            strings={strings}
                          />
                        ))}
                      {sortedElements(joinElements(normElements ? normElements : [], mandatoryElements)).map(
                        (element: string, index: number) => (
                          <Composition
                            index={FAKE_INDEX++}
                            element={element}
                            min={compositionObj[element]?.min && compositionObj[element].min}
                            max={compositionObj[element]?.max && compositionObj[element].max}
                            unit={compositionObj[element]?.unit}
                            key={element + index + 'm'}
                            onRemove={handleRemove}
                            strings={strings}
                          />
                        ),
                      )}
                      {selectedElements !== undefined &&
                        sortedElements(selectedElements).map((element: string, index: number) => (
                          <Composition
                            index={FAKE_INDEX++}
                            element={element}
                            min={compositionObj[element]?.min && compositionObj[element].min}
                            max={compositionObj[element]?.max && compositionObj[element].max}
                            unit={compositionObj[element]?.unit}
                            key={element + (index + mandatoryElements.length)}
                            onRemove={handleRemove}
                            activeElements={activeElements}
                            setSelectedElements={setSelectedElements}
                            selectedElements={selectedElements}
                            strings={strings}
                          />
                        ))}
                    </Table>
                    <VSeparator />
                    <Divisor />
                    <VSeparator />
                    <Hbox vAlign='center'>
                      <Hbox.Item>
                        <ButtonText onClick={() => setStep(2)}>
                          <FaIcon.Plus size='1x' /> {strings.btnAdd}
                        </ButtonText>
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <Button kind='primary' expanded type='submit'>
                          {strings.btnSave}
                        </Button>
                      </Hbox.Item>
                    </Hbox>
                    <VSeparator />
                  </Form>
                </CompositionTabForm>

                <CompositionTabList step={step}>
                  <CompositionTabsWrapperStyled>
                    <CompositionTab tabIndex={tabIndex} labels={strings.tabs} handleTabChange={handleTabChange} />
                  </CompositionTabsWrapperStyled>

                  <CompositionContentWrapperStyled>
                    <VSeparator />
                    <CompositionList
                      strings={strings}
                      id={0}
                      numCols={6}
                      tabIndex={tabIndex}
                      listElements={sortedElements(simpleElements)}
                      elements={mandatoryElements}
                      selectedElements={selectedElements}
                      normElements={normElements}
                      handleSelectedElements={handleSelectedElements}
                    />
                    <CompositionList
                      strings={strings}
                      id={1}
                      numCols={12}
                      tabIndex={tabIndex}
                      listElements={sortedElements(compositeElements)}
                      elements={mandatoryElements}
                      selectedElements={selectedElements}
                      normElements={normElements}
                      handleSelectedElements={handleSelectedElements}
                    />
                  </CompositionContentWrapperStyled>
                  <Divisor />
                  <VSeparator />
                  <Hbox hAlign='flex-end'>
                    <Hbox.Item noGrow>
                      <Button kind='secondary' expanded onClick={() => setStep(1)}>
                        {strings.btnBack}
                      </Button>
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item noGrow>
                      <Button kind='primary' expanded onClick={() => setStep(1)}>
                        {strings.btnSelectedElements}
                      </Button>
                    </Hbox.Item>
                  </Hbox>
                  <VSeparator />
                </CompositionTabList>
              </>
            </Col>
          </Row>
        </Grid>
      </Scroll.Element>
    </Modal>
  );
};

ModalComposition.defaultProps = {
  openTableType: '',
};
