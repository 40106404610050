import React from 'react';
import { OrderInputTemplateDownload } from '@app/modules/order-input/components/order-input-template-download/order-input-template-download';
import { H4, Caption } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { OptionWrapper } from './order-input-option.style';

interface OrderInputOptionProps {
  children: JSX.Element;
  title: string;
  subtitle: string;
  active?: boolean;
  templateDownload?: boolean;
  onClick: () => void;
  testid: string;
}

export const OrderInputOption: React.FC<OrderInputOptionProps> = ({
  children,
  title,
  subtitle,
  active,
  templateDownload,
  onClick,
  testid,
}) => {
  const handleTemplateDownloadComponent = () => {
    if (templateDownload) {
      return (
        <FlexRow mt={Spacing.Small}>
          <OrderInputTemplateDownload />
        </FlexRow>
      );
    } else {
      return null;
    }
  };

  return (
    <OptionWrapper data-testid={testid} onClick={onClick} active={active}>
      <FlexColumn mr pl pt>
        <FlexRow mb>{children}</FlexRow>
        <H4>{title}</H4>
        <Caption mt={Spacing.Small}>{subtitle}</Caption>
        {handleTemplateDownloadComponent()}
      </FlexColumn>
    </OptionWrapper>
  );
};
