import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../order-input.constants';
import { IOrderInputState } from '../order-input.interfaces';
import { initialState } from './order-input.initial';
import { reducers } from './order-input.reducers';
import { addOrderInputReducers } from './thunks/order-input.thunks';

export const orderUpdateSlice = createSlice<IOrderInputState, SliceCaseReducers<IOrderInputState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addOrderInputReducers,
});

export const {
  setPhase,
  setIssuerOptions,
  setReceiverOptions,
  setMaterialOptions,
  setOpenMaterials,
  setSelectedMaterials,
  removeSelectedMaterialOption,
  setSelectedReceiver,
  setSelectedIssuer,
  setSearchBy,
  setQuery,
  updateMaterial,
  setPdfBase64,
  setEditNewLine,
  setConfigColumnsSpreadsheet,
  setSpreadSheetFile,
  setSpreadSheetFileName,
  setSpreadSheetFileRequestItems,
  setMaterialFieldID,
  setMaterialTemplate,
  setImplantationType,
  setShowM2CalendarModal,
  setSelectAllMaterials,
  setDeleteMaterialModalOpened,
  setUpdateMaterialModalOpened,
  setMaterialDelete,
  setSelectAll,
  setOpenMultipleAlertModal,
  setMultipleUpQuantity,
  setMultipleDownQuantity,
  setOpenConfigColumnSpreadsheet,
  setMaterialCodeColumn,
  setAmountColumn,
  setUnityAmountColumn,
  setDateWishedColumn,
  setOrderNumberColumn,
  setItemIdentifierColumn,
  setInitRow,
  setIdentifier,
} = orderUpdateSlice.actions;

export const orderInputReducer = orderUpdateSlice.reducer;
