import { Chat, ChatResponse, Option, Question, UnfinishedChatsResponse } from '@app/models';
import {
  AnalisysResponseProduct,
  AnalysisResponse,
  AnalysisResume,
  AnalysisStatus,
  LegacyMetadata,
  SimilarMaterialsCol,
  SimilarMaterialsColResponse,
  SimilarMaterialsItem,
  SimilarMaterialsResponse,
  SimilarMaterialsRowResponse,
} from '@app/models/analysis.model';
import { HistoryStatus } from '@app/models/history.model';
import {
  AnalysisCreatedBy,
  AnalysisSpecification,
  FlowType,
  FullAnalysisData,
  Plant,
  PlantReview,
  PlantSelection,
  PlantValidation,
  QuoteAttributeComplement,
  QuoteAttributeDerogate,
  QuoteAttributeDerogatePermissions,
  QuoteAttributes,
  QuoteAttributeStatus,
  QuoteAttributeTypes,
  QuoteAttributeValue,
  Responsibles,
  SimiliarsMaterialsModalData,
  Stages,
  TableType,
  TextType,
} from '@app/models/quote.model';
//import { Dimensions } from '@app/modules/quote/quote.string';
import { AppPath } from '@app/modules/app/route-constants';
import { Dimensions } from '@app/modules/quote/quote.string';
import { dateParser } from '@app/utils/date-parser';
import { isNullOrUndefined } from '@app/utils/validation';
import { mapChatAttributes } from './chat.dto';
import { GetAssetsResponse, mapGetAssets } from './quote-assets.dto';
import { CommentsFullResponse, mapCommentsResponse } from './quote-comment.dto';

export interface FullAnalysisResponse {
  title: string;
  currentTab: number;
  analysisType?: string;
  formatted_id: string;
  branding?: string;
  legacyMetadata: LegacyMetadata;
  conversation_id: string;
  date: string;
  client: ClientResponse;
  query: any;
  requiredQuery: any;
  plants: PlantResponse[];
  status: FullAnalysisStatusResponse;
  statusStages: Stages;
  permissions: AnalysisPermissionsResponse;
  specifications: AnalysisSpecification[];
  price?: number;
  priceDate?: string;
  currencyCode?: string;
  commentQuote?: string;
  flow: { type: string; label: string };
  assets?: GetAssetsResponse[];
  user: AnalysisCreatedBy;
  allowBaggNotification?: boolean;
  newProductEpp?: boolean;
  flagInternationalStandard?: boolean;
}

export interface ClientResponse {
  CNPJ?: string;
  code?: string;
  country?: string;
  name?: string;
}

export interface SimilarsMaterialResponse {
  similarMaterials: SimilarMaterialsResponse;
}

export interface AnalysisPermissionsResponse {
  isEditable: boolean;
  isDuplicatable: boolean;
  extraInfos: boolean;
  assets: {
    isAddible: boolean;
  };
}

export interface FullAnalysisStatusResponse {
  current: CurrentStatusResponse;
  history: HistoryStatusResponse[];
  stages: Stages[];
}

export interface HistoryStatusResponse {
  createdBy: AnalysisCreatedBy;
  createdDate: string;
  observation: string;
  observationClient?: string;
  internal: AnalysisStatus;
  external: AnalysisStatus;
  updatedBy: AnalysisCreatedBy;
  updatedDate: string;
  skus?: string[];
  newProductEppJustify?: string;
}

export interface CurrentStatusResponse {
  createdBy: AnalysisCreatedBy;
  createdDate: string;
  observation: string;
  observationClient?: string;
  internal: AnalysisStatus;
  external: AnalysisStatus;
  responsibles: Responsibles[];
  skus?: string[];
  cancelRequestAllowed?: boolean;
  cancelRequestReason?: string;
  askPartNumber?: boolean;
  newProductEppJustify?: string;
}

export interface PlantResponse {
  key: string;
  label: string;
  attributes: QuoteAttributesResponse[];
  review: PlantReview;
  selection: PlantSelection;
  validation: PlantValidation;
  permissions: PlantPermissionsResponse;
  hasResume?: boolean;
  hasDerogate?: boolean;
}

export interface PlantPermissionsResponse {
  attributes: {
    isAddible: boolean;
    isReplicable: boolean;
  };
}

export enum CurrencyCode {
  BRL = 'R$',
  USD = '$',
  EUR = '€',
}

export interface AttributesCreation {
  origin?: string;
}

export interface Allowed {
  key: string;
  label: string;
}

export interface QuoteAttributesResponse {
  key: string;
  label: string;
  allowed?: Allowed[];
  detail?: string;
  type: QuoteAttributeTypes;
  tableType?: TableType;
  textType?: TextType;
  status: QuoteAttributeStatus;
  value: number | boolean | string | QuoteAttributeValue | QuoteAttributeValue[];
  comments: CommentsFullResponse[];
  isEditable: boolean;
  isDeletable: boolean;
  unit?: any;
  derogate?: QuoteAttributeDerogateResponse;
  permissions: AttributesPermissionsResponse;
  complementaries: QuoteAttributeComplementResponse[];
  creation?: AttributesCreation;
  testMethod?: string;
  isCritical?: boolean;
  sequence?: number;
}

export interface AttributesPermissionsResponse {
  isEditable: boolean;
  isDelible: boolean;
  comments: {
    isAddible: boolean;
    isReplicable: boolean;
  };
}

export interface TableGraphicHardness {
  count: number[];
  mean: number[];
  std: number[];
}

export interface ChartGraphicHardness {
  count_production: any;
  kde: any;
  lsl: number;
  media: number;
  usl: number;
}

export interface QuoteAttributeComplementResponse {
  key: string;
  label: string;
  type: QuoteAttributeTypes;
  value: any;
  table?: TableGraphicHardness;
  chart?: ChartGraphicHardness;
}

export interface QuoteAttributeDerogateResponse {
  images?: File[];
  reason: string;
  suggestion: {
    value: any;
    status: {
      value: string;
      permissions?: QuoteAttributeDerogatePermissions;
    };
  };
}

export const chatResponseToChat = (chatResponse: ChatResponse): Chat => {
  const questionsResponse: Question[] = chatResponse.output.generic.map(mapperQuestionResponseToQuestion);
  const chat: Chat = {
    questions: questionsResponse,
    attributes: mapChatAttributes(chatResponse.attributes),
    context: chatResponse.context,
    forceRedirect: chatResponse.context.forceRedirect ? chatResponse.context.forceRedirect : false,
  };
  return chat;
};

export const chatResponseToChatUnfinished = (chatResponse: ChatResponse): Chat => {
  const questions = [];
  const answers = [];

  chatResponse.messages?.map((message, index) => {
    const lastq = message.output.generic.pop();

    if (lastq) {
      questions[index] = mapperQuestionResponseToQuestion(lastq);
      if (index > 0) {
        if (message.input.text !== undefined) {
          answers[index - 1] = message.input.text;
        }
      }
    }
  });

  const completeArray = questions.map((item, index) => {
    return {
      answer: answers[index],
      description: item.description,
      options: item.options,
      question: item.question,
      questionType: item.questionType,
    };
  });

  const chat: Chat = {
    questions: completeArray,
    attributes: mapChatAttributes(chatResponse.attributes),
    context: chatResponse.context,
    forceRedirect: chatResponse.context.forceRedirect ? chatResponse.context.forceRedirect : false,
  };
  return chat;
};

export const mapAnalysis = (analysisResponse: AnalysisResponse[], paginationcount?: number, language?: string) => {
  return analysisResponse.map(item => {
    return {
      branding: item.branding,
      client: {
        code: item.client.code,
        name: item.client.name,
        visible: item.client.visible,
      },
      flow: {
        label: item.flow.label,
        type: item.flow.type,
      },
      createdDate: new Date(item.createdDate),
      formattedId: item.formattedId,
      id: item.id,
      plants: item.plants,
      hasPrice: item.hasPrice,
      priority: {
        label: item.priority.label,
        date: item.priority.date ? new Date(item.priority.date) : null,
      },
      product: {
        shape: item.product.shape,
        steelName: item.product.steelName,
        dimensional: mapDimensional(item.product, language),
      },
      status: {
        external: {
          key: item.status.external.key,
          label: item.status.external.label,
        },
        internal: {
          key: item.status.internal.key,
          label: item.status.internal.label,
        },
      },
      title: item.title.toLocaleUpperCase(),
      userCreator: {
        email: item.userCreator.email,
        firstName: item.userCreator.firstName,
        lastName: item.userCreator.lastName,
        preferredLanguage: item.userCreator.preferredLanguage,
        profile: item.userCreator.profile,
        type: item.userCreator.type,
      },
      paginationCount: paginationcount,
    };
  });
};

const mapDimensional = (item: AnalisysResponseProduct, language?: string): string => {
  let dimensional = '';
  if (item.diameter) {
    dimensional += `${Dimensions[language].bitola}: ${item.diameter} mm `;
  }
  if (item.side) {
    dimensional += `${Dimensions[language].lado}: ${item.side} mm `;
  }
  if (item.width) {
    dimensional += `${Dimensions[language].largura}: ${item.width} mm `;
  }
  if (item.thickness) {
    dimensional += `- ${Dimensions[language].espessura}: ${item.thickness} mm `;
  }
  return dimensional;
};

const reorderPlants = (plants: PlantResponse[]) => {
  const gerdauPlant = plants.filter(function(plant) {
    return plant.hasResume === true;
  });
  const othersPlants = plants.filter(function(plant) {
    return plant.hasResume !== true;
  });
  othersPlants.sort(function(a, b) {
    if (a.key < b.key) {
      return -1;
    } else if (a.key > b.key) {
      return 1;
    } else {
      return 0;
    }
  });

  return othersPlants.concat(gerdauPlant);
};

export const mapSimiliarMaterials = (response: SimilarsMaterialResponse): SimiliarsMaterialsModalData => {
  return {
    similarMaterials: {
      columns: mapSimiliarMaterialsCols(response.similarMaterials?.columns),
      rows: mapSimilarMaterialsRows(response.similarMaterials?.rows),
    },
  };
};

const mapDerogate = attributeResponse => {
  let hasDerogate = false;
  attributeResponse.map(item => {
    if (item.derogate?.reason) {
      hasDerogate = true;
    }
  });
  return hasDerogate;
};

export const mapFullAnalysis = (response: FullAnalysisResponse): FullAnalysisData => {
  const currentStatus = response.status?.current;

  const allPlants = reorderPlants(response.plants);

  let currentTab = 0;
  if (
    currentStatus.external?.key === 'UNDER_ANALYSIS' ||
    currentStatus.external?.key === 'WAITING_CLIENT_REVIEW' ||
    currentStatus.internal?.key === 'WAITING_CLIENT_REVIEW'
  ) {
    allPlants.forEach((plant, index) => {
      if (plant.review.isReviewed && plant.selection.isSelected) {
        currentTab = index;
      }
    });
  }

  return {
    title: response.title,
    hasDerogate: mapDerogate(allPlants),
    currentTab: currentTab,
    branding: response.branding,
    analysisType: response.analysisType,
    requiredSteel: response.query.STEEL_NAME,
    formattedId: response.formatted_id,
    legacyId: response.legacyMetadata?.formatted_id,
    client: response.client?.name ? response.client?.name : response.query.CLIENT,
    bp: response.client?.code ? response.client?.code : '',
    priority: response.query.PRIORITY,
    createdBy: response.status?.history[0]?.createdBy,
    creationDate: !!currentStatus && dateParser({ unformattedDate: response.date }),
    unformattedCreationDate: response.date,
    internalStatus: currentStatus?.internal,
    externalStatus: currentStatus?.external,
    askPartNumber: currentStatus.askPartNumber,
    cancelRequestAllowed: currentStatus.cancelRequestAllowed,
    cancelRequestReason: currentStatus.cancelRequestReason,
    newProductEpp: response.query.NEW_PRODUCT_EPP,
    flagInternationalStandard: response.query.flagInternationalStandard,
    statusStages: {
      stages: response.status.stages,
    },
    responsibles: response.status.current.responsibles,
    plants: mapPlants(allPlants),
    permissions: {
      isEditable: response.permissions.isEditable,
      isDuplicatable: response.permissions.isDuplicatable,
      extraInfos: response.permissions.extraInfos,
      assetsEditable: !!response.permissions?.assets.isAddible,
    },
    requiredQuery: response.query,
    specification: response.specifications,
    history: mapHistoryStatus(response.status?.history, response.status?.current),
    price: response.price,
    priceDate: dateParser({ unformattedDate: response.priceDate, hideHours: true }),
    currencyCode: CurrencyCode[response.currencyCode],
    priceObservation: response.commentQuote,
    assets: mapGetAssets(response.assets),
    flow: response.flow.label,
    flowType: response.flow.type,
    conversationId: response.conversation_id,
    editedAttributes: response.query.editedAttributes,
    user: response.user,
    allowBaggNotification: response.allowBaggNotification,
  };
};

const mapHistoryStatus = (
  historyStatusResponse: HistoryStatusResponse[] = [],
  current: CurrentStatusResponse,
): HistoryStatus[] => {
  const currentStatusHistory: HistoryStatusResponse = {
    internal: current?.internal,
    external: current?.external,
    createdBy: current?.createdBy,
    createdDate: current?.createdDate,
    updatedBy: current?.createdBy,
    updatedDate: current?.createdDate,
    observation: current?.observation,
    observationClient: current?.observationClient,
    newProductEppJustify: current?.newProductEppJustify,
    skus: current?.skus,
  };

  const histories = [...historyStatusResponse];
  const provHistory = [...historyStatusResponse];

  const lastProvHistory = provHistory.pop();
  if (lastProvHistory?.internal?.key !== currentStatusHistory?.internal?.key) {
    histories.push(currentStatusHistory);
  }

  return histories
    .sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
    .map(history => {
      return {
        createdBy: history?.createdBy,
        createdDate: !!history && dateParser({ unformattedDate: history.createdDate }),
        observation: history?.observation,
        observationClient: history?.observationClient,
        newProductEppJustify: history?.newProductEppJustify,
        internal: history?.internal,
        external: history?.external,
        updatedBy: history?.updatedBy,
        updatedDate: !!history && dateParser({ unformattedDate: history.updatedDate }),
        skus: history?.skus,
      };
    });
};

const mapPlants = (plantResponse: PlantResponse[]): Plant[] =>
  plantResponse.map(item => ({
    key: item.key,
    hasDerogate: mapDerogate(item.attributes),
    label: item.label,
    attributes: mapAttributes(item.attributes),
    review: item.review,
    selection: item.selection,
    validation: item.validation,
    hasResume: item.hasResume,
    permissions: {
      attributesAddible: item.permissions?.attributes ? !!item.permissions?.attributes.isAddible : false,
      attributesReplicable: item.permissions?.attributes ? !!item.permissions?.attributes.isReplicable : false,
    },
  }));

const mapAttributes = (attributeResponse: QuoteAttributesResponse[]): QuoteAttributes[] => {
  const attributes = attributeResponse.map(item => {
    return {
      key: item.key,
      label: item.label,
      allowed: item.allowed,
      detail: item.detail,
      type: item.type,
      tableType: item.tableType,
      textType: item.textType,
      status: item.status,
      attributeValue: item.value as QuoteAttributeValue,
      arrayAttributeValue: item.value as QuoteAttributeValue[],
      numberAttributeValue: item.value as number,
      booleanAttributeValue: item.value as boolean,
      dateAttributeValue:
        item.type === QuoteAttributeTypes.Date
          ? dateParser({ unformattedDate: `${item.value}`, hideHours: true, utc: true })
          : '',
      comments: mapCommentsResponse(item.comments),
      isEditable: item.isEditable,
      isDeletable: item.isDeletable,
      unit: item.unit,
      derogate: mapDerogateAttribute(item.derogate, item.unit),
      permissions: {
        isEditable: item.permissions.isEditable,
        isDelible: item.permissions.isDelible,
        commentsAddible: !!item.permissions?.comments.isAddible,
        commentsReplicable: !!item.permissions?.comments.isReplicable,
      },
      complementaries: mapComplementaries(item),
      creation: item.creation?.origin,
      testMethod: item.testMethod,
      isCritical: item.isCritical,
      sequence: item.sequence,
    };
  });

  attributes.sort((a, b) => a.sequence - b.sequence);
  return attributes;
};

const mapComplementaries = (attribute: QuoteAttributesResponse): QuoteAttributeComplement[] => {
  return attribute.complementaries?.map(item => {
    return {
      key: item.key,
      label: item.label,
      type: item.type,
      tableValue: item.type === QuoteAttributeTypes.Table && (item.value as QuoteAttributeValue),
      textValue: item.type === QuoteAttributeTypes.Text && (item.value as string),
      chartValue: item.type === QuoteAttributeTypes.ChartTable && item.value,
    };
  });
};

const mapDerogateAttribute = (derogate: QuoteAttributeDerogateResponse, unit?: any): QuoteAttributeDerogate => {
  const value = derogate?.suggestion?.value;
  const dimentional = unit?.value ? unit.value : '';
  const minDimentional = unit?.value?.min ? unit.value.min : '';
  const maxDimentional = unit?.value?.max ? unit.value.max : '';

  const min = !isNullOrUndefined(value?.min) ? `Min: ${value?.min} ${minDimentional}` : '';
  const max = !isNullOrUndefined(value?.max) ? `Max: ${value?.max} ${maxDimentional}` : '';

  const suggestion =
    typeof value === 'string' || typeof value === 'number'
      ? `${value} ${dimentional}`
      : typeof value === 'boolean'
      ? value
        ? 'Sim'
        : 'Não'
      : { text: `${min} ${max}`, min: value?.min, max: value?.max };

  return {
    images: derogate?.images,
    reason: derogate?.reason,
    suggestion,
    suggestionStatus: derogate?.suggestion?.status?.value,
    permissions: derogate?.suggestion?.status?.permissions,
  };
};

const mapperQuestionResponseToQuestion = (questionResponse: any) => {
  const questionitem: Question = {
    question: questionResponse.text || questionResponse.title,
    questionType: questionResponse.response_type,
    options: questionResponse.options ? questionResponse.options.map(mapperOptionsResponseToOptions) : {},
    description: questionResponse?.description?.length > 0 && questionResponse?.description,
  };

  return questionitem;
};

export const mapUnfinishedChat = (unfinishedChatsResponse: AnalysisResume) => {
  return {
    key: unfinishedChatsResponse[0].key,
    id: unfinishedChatsResponse[0].conversation_id,
    feedback: unfinishedChatsResponse[0].feedback,
    reviewed: unfinishedChatsResponse[0].reviewed,
    createdDate: new Date(unfinishedChatsResponse[0].start_date),
  };
};

export const mapSurfaceFinish = (value: string) => {
  const replaceDash = value.replace('_', ' ');
  return replaceDash[0] + replaceDash.substring(1).toLowerCase();
};

export const mapHeatTreatment = (value: []) => {
  if (value) {
    value.forEach(item => {
      return mapSurfaceFinish(item);
    });
  }
};

export const mapUnfinishedListTitle = (surface: string, shape: string, steel: string) => {
  return `${surface ? mapSurfaceFinish(surface) : ''} ${shape ? mapSurfaceFinish(shape) : ''} ${steel ? steel : ''}`;
};

export const mapUnfinishedChatsList = (unfinishedChatsResponse: UnfinishedChatsResponse[]) => {
  return unfinishedChatsResponse.map(item => ({
    key: item.key,
    id: item.conversation_id,
    feedback: item.feedback,
    reviewed: item.reviewed,
    createdDate: new Date(item.start_date),
    client: {
      code: item.CLIENT,
    },
    title: mapUnfinishedListTitle(item.SURFACE_FINISH, item.SHAPE, item.STEEL_NAME),
    product: {
      shape: item.SHAPE && mapSurfaceFinish(item.SHAPE),
      steelName: item.STEEL_NAME,
      dimensional: item.DIAMETER && `Bitola: ${item.DIAMETER} mm`,
    },
    flow: {
      label: FlowType[item.FLOW],
    },
    surfaceFinish: item.SURFACE_FINISH && mapSurfaceFinish(item.SURFACE_FINISH),
  }));
};

// tslint:disable-next-line:cyclomatic-complexity
// const mapperContextResponseToContext = (contextResponse: any) => {

//   const context: Context = {
//   acabamento: contextResponse.ACABAMENTO ? contextResponse.ACABAMENTO : '',
//   aceiteCurtos: contextResponse.ACEITE_CURTOS ? contextResponse.ACEITE_CURTOS : '',
//   acoCliente: contextResponse.STEEL_NAME ? contextResponse.STEEL_NAME : '',
//   acoNotFound: contextResponse.ACO_NOT_FOUND ? contextResponse.ACO_NOT_FOUND : '',
//   acoType: contextResponse.ACO_TYPE ? contextResponse.ACO_TYPE : '',
//   aplicacaoFinal: contextResponse.APLICACAO_FINAL ? contextResponse.APLICACAO_FINAL : '',
//   bitola: contextResponse.BITOLA ? contextResponse.BITOLA : '',
//   cliente: contextResponse.CLIENTE ? contextResponse.CLIENTE : '',
//   comprimentoMin: contextResponse.COMPRIMENTOMIN ? contextResponse.COMPRIMENTOMIN : '',
//   comprimentoMax: contextResponse.COMPRIMENTOMAX ? contextResponse.COMPRIMENTOMAX : '',
//   comprimentoMedidaMin: contextResponse.COMPRIMENTO_MEDIDA_MIN ? contextResponse.COMPRIMENTO_MEDIDA_MIN : '',
//   comprimentoMedidaMax: contextResponse.COMPRIMENTO_MEDIDA_MAX ? contextResponse.COMPRIMENTO_MEDIDA_MAX : '',
//   elementos: contextResponse.ELEMENTOS ? contextResponse.ELEMENTOS : null,
//   embCurtos: contextResponse.EMB_CURTOS ? contextResponse.EMB_CURTOS : '',
//   empenamento: contextResponse.EMPENAMENTO ? contextResponse.EMPENAMENTO : '',
//   foma: contextResponse.FORMA ? contextResponse.FORMA : '',
//   medidaBitola: contextResponse.MEDIDA_BITOLA ? contextResponse.MEDIDA_BITOLA : '',
//   mercado: contextResponse.MERCADO ? contextResponse.MERCADO : '',
//   porcCurtos: contextResponse.PORC_CURTOS ? contextResponse.PORC_CURTOS : '',
//   processoCliente: contextResponse.PROCESSO_CLIENTE ? contextResponse.PROCESSO_CLIENTE : '',
//   produto: contextResponse.PRODUTO ? contextResponse.PRODUTO : '',
//   tamanhoCurtosMax: contextResponse.TAMANHO_CURTOSMAX ? contextResponse.TAMANHO_CURTOSMAX : '',
//   tamanhoCurtosMin: contextResponse.TAMANHO_CURTOSMIN ? contextResponse.TAMANHO_CURTOSMIN : '',
//   tamanhoCurtosMedidaMax: contextResponse.TAMANHO_CURTOS_MEDIDA_MAX ? contextResponse.TAMANHO_CURTOS_MEDIDA_MAX : '',
//   tamanhoCurtosMedidaMin: contextResponse.TAMANHO_CURTOS_MEDIDA_MIN ? contextResponse.TAMANHO_CURTOS_MEDIDA_MIN : '',
//   tolBitolaMax: contextResponse.TOL_BITOLAMAX ? contextResponse.TOL_BITOLAMAX : '',
//   tolBitolaMin: contextResponse.TOL_BITOLAMIN ? contextResponse.TOL_BITOLAMIN : '',
//   tolComprimentoMax: contextResponse.TOL_COMPRIMENTOMAX ? contextResponse.TOL_COMPRIMENTOMAX : '',
//   tolComprimentoMin: contextResponse.TOL_COMPRIMENTOMIN ? contextResponse.TOL_COMPRIMENTOMIN : '',
//   tolComprimentoMedidaMax: contextResponse.TOL_COMPRIMENTO_MEDIDA_MAX ?
// contextResponse.TOL_COMPRIMENTO_MEDIDA_MAX : '',
//   tolComprimentoMedidaMin: contextResponse.TOL_COMPRIMENTO_MEDIDA_MIN ?
// contextResponse.TOL_COMPRIMENTO_MEDIDA_MIN : '',
//   tratamentoTermico: contextResponse.TRATAMENTO_TERMICO ? contextResponse.TRATAMENTO_TERMICO : '',
//   conversationId: contextResponse.conversation_id ? contextResponse.conversation_id : '',
//   edit: contextResponse.edit ? contextResponse.edit : false,
//   medidas: contextResponse.medidas ? contextResponse.medidas : '',
//   openTableType: contextResponse.openTableType ? contextResponse.openTableType : '',
//   pointer: contextResponse.pointer ? contextResponse.pointer : '',
//   retorno: contextResponse.retorno ? contextResponse.retorno : '',
//   forceRedirect: contextResponse.forceRedirect ? contextResponse.forceRedirect : false,
//   };

//   return context;
// };

const mapperOptionsResponseToOptions = (optionResponse: any) => {
  const option: Option = {
    label: optionResponse.label,
    value: optionResponse.value.input.text,
  };

  return option;
};

const mapSimiliarMaterialsCols = (similarMaterialCols: SimilarMaterialsColResponse[]): SimilarMaterialsCol[] =>
  similarMaterialCols?.map(col => ({
    key: col.key,
    label: col.label,
    type: col.type,
    colSpan: col.colSpan,
    rowSpan: col.rowSpan,
    isVisible: col.isVisible,
    isEditable: col.isEditable,
    isDelible: col.isDelible,
  }));

const mapSimilarMaterialsRows = (similarMaterialsRows: SimilarMaterialsRowResponse[]): SimilarMaterialsItem[] =>
  similarMaterialsRows?.map(({ row }) =>
    row.reduce((accumulator, currentValue) => {
      accumulator[currentValue.key] = currentValue.value;
      return accumulator;
    }, {}),
  );

export const isQuoteChatComplainCrmPage = (): boolean => {
  return (
    location.pathname.includes(AppPath.QUOTE.BASE) ||
    location.pathname.includes(AppPath.CHAT.BASE) ||
    location.pathname.includes(AppPath.COMPLAIN.BASE) ||
    location.pathname.includes(AppPath.QUOTE.CRM) ||
    location.pathname.includes(AppPath.HELP_CENTER) ||
    location.pathname.includes(AppPath.DOCUMENTS) ||
    location.pathname.includes(AppPath.CATALOGS) ||
    location.pathname.includes(AppPath.QUOTE.FORM.BASE)
  );
};

export interface FullAnalysisResponseNew {
  id: number;
  analysisTitle: string;
  analysisType: string;
  product: {
    title: string;
  };
  formattedId: string; // 0000000${id}/year
  createdBy: {
    firstName: string;
    lastName: string;
    formattedName: string; //(firstName + lastName)
    date: string;
  };
  client: {
    formattedName: string; // name + city + state + country
  };
  priority: string;
  standard?: {
    clientVisible: boolean;
    url: string;
  };
  plants: { key: string; label: string }[];
  permissions: {
    // ver com o Plentz
    comments: {
      created: boolean;
      updated: boolean;
      deleted: boolean;
    };
    responsibles: {
      updated: boolean;
      created: boolean;
      deleted: boolean;
    };
    plants: {
      isRequested: boolean;
      isSelected: boolean;
      isValidated: boolean;
    };
  };
}

export interface NewPlantsResponse {
  key: string; //pindamonhangaba, charqueadas, mogiDasCruzes,
  label: string;
  attributes: any;
}
[];
export interface NewAttributesData {
  key: string;
  label: string;
  isCritical: boolean;
  permissions: {
    derogate: boolean;
    delible: boolean;
    editable: boolean;
  };
  creation: {
    origin: string; //GERDAU, CLIENT
  };
  status: string;
  testMethod?: string;
  unit?: string;
  detail?: string;
  complementaries?: {
    url: string;
  };
  derogate?: {
    url: string;
  };
  allowedDerogate?: [
    {
      key: string;
      label: string;
    },
  ];
}
