import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Frame } from '@atomic/atm.frame';
import { H2 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { ConfigColumnsContext } from './components/config-columns-modal/config-columns-modal.context';
import { OrderInputWizard } from './components/order-input-wizard/order-input-wizard';
import { OrderInputPhaseEnum } from './order-input.constants';
import { OrderInputStrings } from './order-input.string';
import { OrderInputAmount } from './phases/order-input-amount/order-input-amount';
import { OrderInputMultiple } from './phases/order-input-multiple/order-input-multiple';
import { OrderInputOptions } from './phases/order-input-options/order-input-options';
import { OrderInputResume } from './phases/order-input-resume/order-input-resume';
import { OrderInputSelectItens } from './phases/order-input-select-itens/order-input-select-itens';
import { OrderInputSuccess } from './phases/order-input-success/order-input-success.component';
import { OrderInputUploadSpreadsheet } from './phases/order-input-upload-spreasheet/order-input-upload-spreadsheet';

export const OrderInputPage: React.FC<{}> = () => {
  const { phase } = useSelector((state: RootState) => state.orderInput);
  const [spreadSheetFile, setSpreadSheetFile] = useState<any>('');
  const Strings = OrderInputStrings.pt;

  const phases = {
    [OrderInputPhaseEnum.Options]: <OrderInputOptions />,
    [OrderInputPhaseEnum.UploadSpreadsheet]: <OrderInputUploadSpreadsheet />,
    [OrderInputPhaseEnum.ItensSelection]: <OrderInputSelectItens />,
    [OrderInputPhaseEnum.DataAndAmout]: <OrderInputAmount />,
    [OrderInputPhaseEnum.Multiple]: <OrderInputMultiple />,
    [OrderInputPhaseEnum.Resume]: <OrderInputResume />,
    [OrderInputPhaseEnum.Success]: <OrderInputSuccess />,
  };

  return (
    <ConfigColumnsContext.Provider
      value={{
        spreadSheetFile,
        setSpreadSheetFile,
      }}
    >
      <Frame>
        <Grid fluid>
          <Row pb>
            <Separator />
            <Col lg={8}>
              <H2 data-testid='order-input-title'>{Strings.title}</H2>
            </Col>
            <Col lg={4}>
              {phase !== OrderInputPhaseEnum.Options && phase !== OrderInputPhaseEnum.Success && (
                <OrderInputWizard phase={phase} />
              )}
            </Col>
            <Separator />
          </Row>
        </Grid>
      </Frame>
      {phases[phase]}
    </ConfigColumnsContext.Provider>
  );
};
