export const DropzoneStrings = {
  pt: {
    uploadClickButton: 'Clique aqui ou arraste o arquivo',
    uploadDropButton: 'Solte o arquivo aqui...',
    inputOrderZoneClick: 'Clique aqui',
    inputOrderZoneDrop: 'ou arraste o arquivo',
    inputOrderAcceptedTypes: 'Formatos aceitáveis: xlsx, xls, .csv',
  },
  in: {
    uploadClickButton: 'Click here or drag the file',
    uploadDropButton: 'Drop the file here...',
    inputOrderZoneClick: 'Click here',
    inputOrderZoneDrop: 'or drag the file',
    inputOrderAcceptedTypes: 'Acceptable formats: xlsx, xls, .csv',
  },
  es: {
    uploadClickButton: 'Haga clic aquí o arrastre el archivo',
    uploadDropButton: 'Suelta el archivo aquí...',
    inputOrderZoneClick: 'Haga clic aquí',
    inputOrderZoneDrop: 'o arrastre el archivo',
    inputOrderAcceptedTypes: 'Formatos aceptables: xlsx, xls, .csv',
  },
};
