import { Material } from '@app/models';
import { MaterialCellError, MaterialUnity } from '@app/modules/order-input/order-input.constants';

export interface IMaterialValidationErrorMessages {
  code: string;
  details: string;
  message: string;
}

export interface IMaterialValidationItem {
  datetCustomerRequired: string;
  materialCode: string;
  materialDescription: string;
  messages: IMaterialValidationErrorMessages[];
  numberOrder: string;
  orderItem: string;
  quantity: number;
  sku: string;
  unitOfMeasure: string;
  plant: string;
}
export interface IMaterialsValidationResponse {
  requests: IMaterialValidationItem[];
}

export interface IMultipleValidation {
  material?: string;
  quantity?: string;
  unitOfMeasure?: string;
  plant?: string;
  multiple?: string;
  quantityMultipleUp?: string;
  quantityMultipleDown?: string;
}

export interface IMultipleValidationResponse {
  multiple: IMultipleValidation[];
}

export const mapMultipleValidation = (response: IMultipleValidationResponse): IMultipleValidation[] => {
  return response.multiple;
};

export const mapMaterialsValidationResponseToMaterials = (response: IMaterialsValidationResponse): Material[] => {
  const materialItems: Material[] = [];

  if (response?.requests?.length) {
    response.requests.map((request, index) => {
      const material: Material = {
        tempId: new Date().getTime().toString() + index,
        orderItem: request.orderItem,
        materialCode: request.sku,
        materialDescription: request.materialDescription,
        materialName: request.materialCode,
        amount: handleAmountValue(request.quantity),
        unity: request.unitOfMeasure === MaterialUnity.Ton ? MaterialUnity.Ton : MaterialUnity.Kg,
        date: handleDate(request),
        orderNumber: request.numberOrder,
        messages: request.messages,
        plant: request.plant,
      };

      materialItems.push(material);
    });
  }

  return materialItems;
};

export const handleAmountValue = amount => {
  if (amount) {
    if (amount.toString().indexOf('.') > -1) {
      return amount.toString().replace('.', ',');
    } else {
      return amount.toString();
    }
  }
  return null;
};

export const handleDate = (material: any): number => {
  if (hasInvalidDate(material.messages) || !material.datetCustomerRequired) {
    return null;
  }

  const [day, month, year] = material.datetCustomerRequired.split('/');
  return new Date(`${year}/${month}/${day}`).getTime();
};

export const hasInvalidDate = (messages: any[]) => {
  let isInvalidDate = false;

  if (messages && messages.length) {
    messages.forEach(m => {
      if (m.code === MaterialCellError.InvalidDateFormat) {
        isInvalidDate = true;
      }
    });
  }

  return isInvalidDate;
};
