import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { handleNumberInput } from '@app/modules/order-input/order-input.utils';
import { hasSomeM2DateSelector } from '@app/modules/order-input/store/order-input.selectors';
import { FlashMessageTargetName } from '@app/providers';
import { Table } from '@atomic/mol.table';
import { FlexColumn } from '@atomic/obj.box';
import { AmountTableBody } from './amount-table-body/amount-table-body';
import { AmountTableControls } from './amount-table-controls/amount-table-controls';
import { AmountTableEditRow } from './amount-table-edit-row/amount-table-edit-row';
import { AmountTableHeader } from './amount-table-header/amount-table-header';
import { AmountTableStyled } from './amount-table.style';
import { DateWarning } from './date-warning/date-warning';
import { DeleteMaterialConfirmModal } from './delete-material-confirm-modal/delete-material-confirm-modal';
import { M2CalendarModal } from './m2-calendar-modal/m2-calendar-modal';
import { MultipleWarning } from './multiple-warning/multiple-warning';
import { UpdateMaterialModal } from './update-material-modal/update-material-modal';
import { hasErrorsOnLoad } from './utils';

const strings = OrderInputStrings.pt.dateAndAmount;

export const AmountTable: React.FC = () => {
  const { selectedMaterials, editedNewline } = useSelector((state: RootState) => state.orderInput);
  const hasSomeM2Date = useSelector(hasSomeM2DateSelector);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  useEffect(() => {
    if (hasErrorsOnLoad(selectedMaterials)) {
      show('alert', strings.spreadSheetInfoError);
    }

    handleNumberInput();
  }, []);

  return (
    <FlexColumn mt mr>
      <MultipleWarning />
      {hasSomeM2Date && <DateWarning />}
      <AmountTableControls />
      <AmountTableStyled>
        <Table>
          <AmountTableHeader />
          <AmountTableBody />
          {editedNewline && <AmountTableEditRow />}
        </Table>
      </AmountTableStyled>
      <DeleteMaterialConfirmModal />
      <UpdateMaterialModal />
      <M2CalendarModal />
    </FlexColumn>
  );
};
