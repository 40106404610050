import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { SimpleToolTip } from '@app/modules/components/SimpleToolTip';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { QuoteStrings } from '../quote.string';
import { DraftDeleteWrapperStyled, HeaderListWrapper, QuotationVisibleWrapperStyled } from './quote.components.style';

interface PropsDelete {
  type: string;
  handleActionToAll: (action: string) => void;
  selectedItems: number;
  isChecked: boolean;
  strings?: any;
}

interface PropsUnfinishedList {
  data: any;
  type: string;
  handleActionToAll: (action?: string, value?: boolean) => void;
  handleAllSelect: (drafts: string[]) => void;
  selectedItems: string[] | number[];
  loading?: boolean;
  toolTipVisible?: boolean;
  handleCloseToolTipClientVisibleStep2?: () => void;
}

export const ActionsButtons = (props: PropsDelete) => {
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(props.selectedItems === 0 && false);

  return (
    <Hbox.Item>
      {props.type === 'quotation' ? (
        <QuotationVisibleWrapperStyled>
          <Button
            kind='primary'
            onClick={() => props.handleActionToAll('make-visible')}
            disabled={props.selectedItems > 0 ? false : true}
          >
            <FaIcon.Eye alt={props.strings.btnShow} />
            <VSeparator /> {props.strings.btnShow}
          </Button>
          <Button
            kind='secondary'
            onClick={() => props.handleActionToAll('make-unvisible')}
            disabled={props.selectedItems > 0 ? false : true}
          >
            <FaIcon.EyeSlash alt={props.strings.btnHide} />
            <VSeparator /> {props.strings.btnHide}
          </Button>
        </QuotationVisibleWrapperStyled>
      ) : (
        <DraftDeleteWrapperStyled>
          {!confirmDelete && props.selectedItems > 0 ? (
            <Button kind='alert' onClick={() => setConfirmDelete(true)}>
              <FaIcon.Custom icon='trash-alt' alt={props.strings.btnRemove} />
              <VSeparator /> {props.strings.btnRemoveSelected}
            </Button>
          ) : (
            <>
              <Button kind='link' onClick={() => props.handleActionToAll('delete')}>
                <Body color={Color.Alert}>{props.strings.btnRemove}</Body>
              </Button>
              /
              <Button kind='link' onClick={() => setConfirmDelete(false)}>
                <Body color={Color.Primary}>{props.strings.btnCancel}</Body>
              </Button>
            </>
          )}
        </DraftDeleteWrapperStyled>
      )}
    </Hbox.Item>
  );
};

export const HeaderListSelect = (props: PropsUnfinishedList) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const strings = QuoteStrings[userInfo.language];

  const list = [];
  props.data.map((item, index) => {
    list[index] = item.id ? item.id : item.conversation_id;
  });

  const handleAllDrafts = (_, checked: boolean) => {
    if (checked) {
      props.handleAllSelect(list);
      setIsChecked(true);
    } else {
      props.handleAllSelect([]);
      setIsChecked(false);
    }
  };

  const handleActionToAll = (action: string) => {
    if (action === 'delete') {
      setIsChecked(false);
      props.handleActionToAll();
    } else {
      props.handleActionToAll(action);
    }
  };

  return (
    <HeaderListWrapper>
      <Hbox.Item noGrow>
        <CheckboxField id='selectAll' checked={isChecked} onValueChange={(_, checked) => handleAllDrafts('', checked)}>
          <H3>{props.type === 'draft' ? strings.headerListSelect.labelDraft : strings.headerListSelect.labelQuote}</H3>
        </CheckboxField>
      </Hbox.Item>
      <LoadingState loading={props.loading} enableActivityIndicator={true}>
        {props.type === 'quotation' ? (
          <ActionsButtons
            type={props.type}
            selectedItems={props.selectedItems.length}
            handleActionToAll={handleActionToAll}
            isChecked={isChecked}
            strings={strings.headerListSelect}
          />
        ) : (
          props.selectedItems.length > 0 && (
            <ActionsButtons
              type={props.type}
              selectedItems={props.selectedItems.length}
              handleActionToAll={handleActionToAll}
              isChecked={isChecked}
              strings={strings.headerListSelect}
            />
          )
        )}
        <SimpleToolTip
          visible={props.toolTipVisible ? props.toolTipVisible : false}
          description={strings.tooltips.clientVisible.step2.description}
          btnLabel={strings.tooltips.clientVisible.step2.btn}
          align='right'
          alignArrow='flex-start'
          customVerticalAlign={15}
          customHorizontalAlign={0}
          steps={strings.tooltips.clientVisible.step2.step}
          onClick={props.handleCloseToolTipClientVisibleStep2}
          onClose={props.handleCloseToolTipClientVisibleStep2}
        />
      </LoadingState>
    </HeaderListWrapper>
  );
};
