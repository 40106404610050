import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { FaIcon } from '@atomic/atm.fa-icon';
import { IOrderInputStrings, OrderInputStrings } from '../../order-input.string';

export const OrderInputTemplateDownload: React.FC<{}> = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  const handleDownloadTemplate = () => {
    LogAnalytics.click({ tipo: 'OrderInputSpreadsheetTemplateDownload' });
    const data = '../../../../../assets/data/Order-Input-Template.xlsx';
    const a = document.createElement<'a'>('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = data;
    a.download = 'modelo-planilha.xlsx';
    a.click();
    window.URL.revokeObjectURL(data);
  };

  return (
    <GridButtonWithIcon
      text={strings?.uploadSpreadSheet?.templateButton}
      icon={FaIcon.FileDownload}
      kind='secondary'
      onClick={() => handleDownloadTemplate()}
    />
  );
};
