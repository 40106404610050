/* eslint-disable prettier/prettier */
// eslint-disable-next-line prettier/prettier
import { startOfMonth } from 'date-fns';
import type { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { parseFloatComma } from '@app/utils/number-utils';
import { hasValues } from '../components/config-columns-modal/utils';
import { validateAmount } from '../order-input-validation';
import { MaterialCellError } from '../order-input.constants';
import { getMounthSum, isM2Date } from '../phases/order-input-amount/partials/amount-table/utils';


export const getReceiverCompanyNameSelector = (state: RootState): string => {
  const option = state.orderInput.receiverOptions.find(item => item?.value === state.orderInput.selectedReceiver);

  if (option?.name && option.name.indexOf('-') > 0) {
    const nameArr = option?.name.split('- ');

    return nameArr && nameArr.length > 2 ? `${nameArr[1]}- ${nameArr[2]}` : nameArr[1];
  }
  return null;
};


export const hasSelectionCompletedSelector = (state: RootState): boolean => {
  const { selectedReceiver, selectedMaterials } = state.orderInput;
  return !!selectedReceiver?.length && !!selectedMaterials?.length;
};

export const hasQtyMultipleAjustedSelector = (state: RootState): boolean => {
  const { selectedMaterials } = state.orderInput;

  const hasSomeZeroQty = selectedMaterials.some((material: Material) => {
    const quantity = material.multipleValidation?.quantity;

    if (quantity) {
      return parseFloatComma(quantity) === 0;
    }

    return false;
  });


  if (hasSomeZeroQty) {
    return false;
  }

  return true;

};

export const hasItemsCompletedSelector = (state: RootState): boolean => {
  const { selectedMaterials, issuerOptions, selectedIssuer } = state.orderInput;
  let hasCompleted = true;

  if (!selectedMaterials?.length) {
    hasCompleted = false;
  }

  let hasError = false;

  const selectedSalesOrg = issuerOptions?.find(option => option.customerId === selectedIssuer?.value)?.salesOrg;

  selectedMaterials.forEach((material: Material) => {
    if (
      !material.tempId ||
      !material.amount ||
      !validateAmount(material, selectedSalesOrg) ||
      !material.unity ||
      !material.date ||
      !material.orderNumber
    ) {
      hasCompleted = false;
    }

    if (material.messages) {
      const filteredMessages = material?.messages.filter(message => message.code !== MaterialCellError.DateOutOfRange);
      if (filteredMessages.length) {
        hasError = true;
      }
    }
  });
  if (hasError) {
    hasCompleted = false;
  }
  return hasCompleted;
};

export const totalItensSelector = (state: RootState): number => {
  const { selectedMaterials } = state.orderInput;
  let total = 0.0;
  selectedMaterials.forEach((material: Material) => {

    let quantity = material.amount;

    if (material.multipleValidation) {
      quantity = material.multipleValidation.quantity;
    }

    if (quantity && material.unity) {
      if (material.unity === 'T') {
        let materialAmount = quantity;
        if (quantity && typeof quantity === 'string' && quantity.indexOf(',') > -1) {
          materialAmount = quantity.replace(',', '.');
        }
        total = total + parseFloat(materialAmount);
      } else {
        total = total + parseFloat(quantity) / 1000;
      }
    }
  });
  return total;
};

export const solicitedTotalItensSelector = (state: RootState): number => {
  const { selectedMaterials } = state.orderInput;
  let total = 0.0;

  selectedMaterials.forEach((material: Material) => {

    if (material.amount && material.unity) {
        if (material.unity === 'T') {
          let materialAmountAjusted = material.amount;
          if (material.amount && typeof material.amount === 'string' && material.amount.indexOf(',') > -1) {
            materialAmountAjusted = material.amount.replace(',', '.');
          }
          total = total + parseFloat(materialAmountAjusted);
        } else {
          total = total + parseFloat(material.amount) / 1000;
        }
      }

  });

  return total;
};

export const hasSpreadSheetFormCompletedSelector = (state: RootState) => {
  const { selectedIssuer, selectedReceiver, searchBy, spreadSheetFileName } = state.orderInput;
  let hasCompleted = false;

  if (selectedIssuer && selectedReceiver && searchBy && spreadSheetFileName) {
    hasCompleted = true;
  }
  return hasCompleted;
};

export const hasSelectedMaterialsSelector = (state: RootState) => {
  const { selectedMaterials } = state.orderInput;
  let hasSelectedMaterial = false;

  if (selectedMaterials) {
    selectedMaterials.forEach(m => {
      if (m.selected) {
        hasSelectedMaterial = true;
      }
    });
  }

  return hasSelectedMaterial;
};


export const hasSomeM2DateSelector = (state: RootState) => {

  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);
  const m2Month = getMounthSum(today);

  const hasSomeM2Date = state.orderInput.selectedMaterials.some((material: Material) => {
    if (material.date) {
      return isM2Date(firstDayOfMonth, new Date(material.date), m2Month);
    }
    return false;
  });

  return hasSomeM2Date;
};

export const isFirstM2CaseSelector = (state: RootState, tempId: string): boolean => {

  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);
  const m2Month = getMounthSum(today);

  const firstM2Material: Material = state.orderInput.selectedMaterials.find((material: Material) => {
    if (material.date) {
      const m2 = isM2Date(firstDayOfMonth, new Date(material.date), m2Month);
      return m2;
    }
    return false;
  });

  if (firstM2Material) {
    return firstM2Material.tempId === tempId;
  }
  return false;
};


export const isNextMultipleDownDisabledSelector = (state: RootState, tempId: string): boolean => {
  const selectedMaterials = state.orderInput.selectedMaterials;
  const selectedMaterial = selectedMaterials.find(item => tempId === item.tempId);
  const quantity = selectedMaterial?.multipleValidation?.quantity;
  const quantityMultipleDown = selectedMaterial?.multipleValidation?.quantityMultipleDown;
  const fixedValueQuantity = parseFloatComma(quantity).toFixed(2);
  const fixedValueQuantityMultipleDown = parseFloatComma(quantityMultipleDown).toFixed(2);

  if (fixedValueQuantity === fixedValueQuantityMultipleDown || fixedValueQuantityMultipleDown === '0.00') {
    return true;
  }
  return false;
};

export const isNextMultipleUpDisabledSelector = (state: RootState, tempId: string): boolean => {
  const selectedMaterials = state.orderInput.selectedMaterials;
  const selectedMaterial = selectedMaterials.find(item => tempId === item.tempId);
  const quantity = selectedMaterial?.multipleValidation?.quantity;
  const quantityMultipleUp = selectedMaterial?.multipleValidation?.quantityMultipleUp;

  if (parseFloatComma(quantity).toFixed(2) === parseFloatComma(quantityMultipleUp).toFixed(2)) {
    return true;
  }
  return false;
};

export const hasSomeMultipleMaterialSelector = (state: RootState): boolean => {
  return state.orderInput.selectedMaterials.some((material: Material) => !!material?.multipleValidation);
};

export const hasConfigurarionSelector = (state: RootState): boolean => {
  return hasValues(state.orderInput.configColumnsSpreadsheet);
};
