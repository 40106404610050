import { addGetIssuersReducers } from './get-issuers.thunk';
import { addMaterialsReducers } from './get-materials.thunk';
import { addGetReceiversReducers } from './get-receivers.thunk';
import { addGetSpreadsheetLayoutThunk } from './get-spreadsheet-layout.thunk';
import { addSubmitOrderReducers } from './post-order.thunk';
import { addPostSpreadsheetLayoutThunk } from './post-spreadsheet-layout.thunk';
import { addValidateAmountMultipleReducers } from './validate-amount-multiple.thunk';
import { addValidateMaterialsReducers } from './validate-materials.thunk';

export const addOrderInputReducers = builder => {
  addSubmitOrderReducers(builder);
  addGetIssuersReducers(builder);
  addGetReceiversReducers(builder);
  addMaterialsReducers(builder);
  addValidateMaterialsReducers(builder);
  addValidateAmountMultipleReducers(builder);
  addGetSpreadsheetLayoutThunk(builder);
  addPostSpreadsheetLayoutThunk(builder);
};
