/* eslint-disable complexity */

import React from 'react';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Form, Validators } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';

export const RangeField = (props: FormFlowProps) => {
  const { formResult, strings, callBacksShorter, loadingCallbackShorter } = React.useContext(FormContext);
  const [min, setMin] = React.useState(null);
  const [max, setMax] = React.useState(null);
  const [unit, setUnit] = React.useState(
    props.field?.unit?.allowed?.length > 1 ? props.field?.unit?.allowed[0]?.symbol : props.field?.unit?.symbol,
  );
  const [firstValue, setFirstValue] = React.useState(true);

  React.useEffect(() => {
    setMin(props?.field?.min && props?.field?.min !== '-' ? props?.field?.min : firstValue && 0);
    setMax(props?.field?.max && props?.field?.max !== '-' ? props?.field?.max : firstValue && 0);
  }, [props.field, firstValue, unit]);

  const stringsNewForm = strings.newForm.messages;

  const inputs: any = document.querySelectorAll(`#${props.field.key}`);

  // if (input && input.type === 'number') {
  //   input.addEventListener('wheel', event => {
  //     event.preventDefault();
  //   });
  // }

  inputs.forEach(input => {
    input.addEventListener('wheel', event => {
      event.preventDefault();
    });
  });

  const getValues = () => {
    setFirstValue(false);
    if (!min || min === undefined || min === '') {
      setMin('');
    }
    if (!max || min === undefined || max === '') {
      setMax('');
    }

    if (min && min !== undefined && max && max !== undefined) {
      if (parseFloat(min) < parseFloat(max)) {
        checkCallback();
      }
    }
    props.handleValue(
      `${!min || min === undefined ? '-' : min.replace(',', '.')} a ${
        !max || max === undefined ? '-' : max.replace(',', '.')
      }`,
      'range',
      props.field.key,
      props.stepKey,
      unit,
    );
  };

  function checkCallback() {
    if (props?.field?.callback && props?.field?.key === 'LENGTH') {
      if (!min.includes('-') && !max.includes('-')) {
        const payload = {
          min: min === '-' ? 0 : min,
          max: max === '-' ? 0 : max,
          version: 1,
        };
        const product = formResult.PRODUCT.questions.fields.PRODUCT.value.key;
        Object.assign(payload, {
          shape: formResult?.PRODUCT?.questions?.fields[`SHAPE_${product}`]?.value?.value,
          gerdauProcess: formResult?.PRODUCT?.questions?.fields[`GERDAU_PROCESS_${product}`]?.value?.value,
          heatTreatment: formResult?.PRODUCT?.questions?.fields[`HEAT_TREATMENT_${product}`]?.value?.value,
          finishing: formResult?.PRODUCT?.questions?.fields.SURFACE_FINISH?.value?.value,
          diameter: formResult?.PRODUCT?.questions?.fields.DIAMETER?.value,
          side: formResult?.PRODUCT?.questions?.fields.SIDE?.value,
          width: formResult?.PRODUCT?.questions?.fields.WIDTH?.value,
          thickness: formResult?.PRODUCT?.questions?.fields.THICKNESS?.value,
          field: 'LENGTH',
        });

        const url = formResult[props.field.step].questions.fields[props.field.key].callback;
        callBacksShorter({ payload, url, action: 'getShorterAttributes' });
      }
    }
  }

  return (
    <LoadingState loading={props?.field?.loading || loadingCallbackShorter}>
      <Table>
        <TR>
          <TD alignContent='baseline' width={45}>
            <Form.Field
              name={`${props.field.key}-min`}
              onValueChange={value => setMin(value)}
              value={min}
              hideErrorCaption
              validators={[
                //Validators.MaxValue(max, min, stringsNewForm.alertMax),
                Validators.Required(stringsNewForm.mandatoryAlert),
                Validators.HasAtLeastOne([min, max], stringsNewForm.mandatoryAlert),
                Validators.MinMoreThanMax([min, max], stringsNewForm.alertMinMoreMax),
              ]}
            >
              <TextField
                id={props.field.key}
                placeholder='Min.'
                type='number'
                noArrows={true}
                onBlur={() => getValues()}
              />
            </Form.Field>
          </TD>
          <TD alignContent='baseline' width={5}>
            -
          </TD>
          <TD alignContent='baseline' width={45}>
            <Form.Field
              name={`${props.field.key}-max`}
              value={max}
              onValueChange={value => setMax(value)}
              validators={[
                Validators.Required(stringsNewForm.mandatoryAlert),
                Validators.HasAtLeastOne([min, max], stringsNewForm.mandatoryAlert),
                Validators.MinMoreThanMax([min, max], stringsNewForm.alertMinMoreMax),
              ]}
            >
              <TextField
                id={props.field.key}
                placeholder='Máx.'
                type='number'
                noArrows={true}
                onBlur={() => getValues()}
              ></TextField>
            </Form.Field>
          </TD>
          {props.field?.unit?.allowed?.length > 1 ? (
            <TD alignContent='baseline' width={5}>
              <Form.Field
                name={props.field.key}
                onValueChange={value => setUnit(value)}
                validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
                value={unit}
              >
                <SelectField id={`${props.field.key}-unit`} onBlur={() => getValues()}>
                  {props.field?.unit.allowed.map((el: { symbol: string; description: string }) => (
                    <option value={el.symbol} key={el.symbol}>
                      {el.description}
                    </option>
                  ))}
                </SelectField>
              </Form.Field>
            </TD>
          ) : (
            <TD alignContent='baseline' width={5}>
              <Body>{props.field?.unit?.symbol}</Body>
            </TD>
          )}
        </TR>
      </Table>
    </LoadingState>
  );
};
