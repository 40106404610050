import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { OrderInputBaseboard } from '@app/modules/order-input/components/order-input-baseboard/order-input-baseboard';
import { OrderInputEvents } from '@app/modules/order-input/order-input.analytics';
import { OrderInputPhaseEnum } from '@app/modules/order-input/order-input.constants';
import { setPhase } from '@app/modules/order-input/store/order-input.store';
import { validateAmountMultiple } from '@app/modules/order-input/store/thunks/validate-amount-multiple.thunk';
import { BackStepConfirmModal } from './back-step-confirm-modal/back-step-confirm-modal.component';

export const OrderInputAmountBaseboard: React.FC = () => {
  const { spreadSheetFileName } = useSelector((state: RootState) => state.orderInput);
  const [openBackStepConfirmModal, setBackStepConfirmModal] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleConfirm = () => {
    LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.UploadSpreadsheet });
    dispatch(setPhase(OrderInputPhaseEnum.UploadSpreadsheet));
  };

  const handleActionClick = () => {
    LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.Multiple });
    dispatch(setPhase(OrderInputPhaseEnum.Multiple));
    dispatch(validateAmountMultiple());
  };

  const handleReturn = () => {
    if (spreadSheetFileName) {
      setBackStepConfirmModal(true);
    } else {
      LogAnalytics.pageView(OrderInputPhaseEnum.ItensSelection);
      dispatch(setPhase(OrderInputPhaseEnum.ItensSelection));
    }
  };
  return (
    <>
      <OrderInputBaseboard onActionClick={() => handleActionClick()} onReturnClick={() => handleReturn()} />
      <BackStepConfirmModal
        opened={openBackStepConfirmModal}
        onClose={() => {
          setBackStepConfirmModal(false);
        }}
        onConfirm={() => handleConfirm()}
      />
    </>
  );
};
